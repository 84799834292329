import Icon, { PlusOutlined } from "@ant-design/icons";
import { Dropdown, Tooltip, Button } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import Link from "next/link";
import { FormattedMessage } from "react-intl";
import { WorkOrderType } from "../../../lib/graphql";
import { useCurrentUser, usePermissions } from "../../../lib/hooks";
import routes from "../../../lib/routes";

export function QuickAddButton() {
  const { currentTenant } = useCurrentUser();
  const permissions = usePermissions((p) => p);
  const height = 22;

  const allowWoType = (type: WorkOrderType) =>
    currentTenant.features.enabledWorkOrderTypes?.includes(type);
  const canAccessWoType = (type: WorkOrderType) =>
    permissions.workOrder?.write && allowWoType(type);

  const items: ItemType[] = [
    canAccessWoType(WorkOrderType.FieldWork)
      ? {
          key: "field-wo",
          icon: (
            <Icon
              component={() => (
                <img src="/images/icons/crops.svg" style={{ height }} />
              )}
            />
          ),
          label: (
            <Link
              href={routes.agro.workOrders.newType(WorkOrderType.FieldWork)}
            >
              <FormattedMessage
                id="workOrders.field"
                defaultMessage="Field Work Order"
              />
            </Link>
          ),
        }
      : null,
    canAccessWoType(WorkOrderType.Harvest)
      ? {
          key: "harvest-wo",
          icon: (
            <Icon
              component={() => (
                <img src="/images/icons/harvest.svg" style={{ height }} />
              )}
            />
          ),
          label: (
            <Link href={routes.agro.workOrders.newType(WorkOrderType.Harvest)}>
              <FormattedMessage
                id="workOrders.harvest"
                defaultMessage="Harvest Work Order"
              />
            </Link>
          ),
        }
      : null,
    canAccessWoType(WorkOrderType.Nursery)
      ? {
          key: "nursery-wo",
          icon: (
            <Icon
              component={() => (
                <img
                  src="/images/menu/agriculture.svg"
                  style={{ height: 20 }}
                />
              )}
            />
          ),
          label: (
            <Link href={routes.agro.workOrders.newType(WorkOrderType.Nursery)}>
              <FormattedMessage
                id="workOrders.nursery"
                defaultMessage="Nursery Work Order"
              />
            </Link>
          ),
        }
      : null,
    canAccessWoType(WorkOrderType.Processing)
      ? {
          key: "processing-wo",
          icon: (
            <Icon
              component={() => (
                <img
                  src="/images/icons/processing-plant.svg"
                  style={{ height }}
                />
              )}
            />
          ),
          label: (
            <Link
              href={routes.agro.workOrders.newType(WorkOrderType.Processing)}
            >
              <FormattedMessage
                id="workOrders.processingWO"
                defaultMessage="Processing Work Order"
              />
            </Link>
          ),
        }
      : null,
    canAccessWoType(WorkOrderType.Transport) ||
    canAccessWoType(WorkOrderType.Repair)
      ? {
          key: "machine-wo",
          icon: (
            <Icon
              component={() => (
                <img
                  src="/images/icons/fleet-machinery.svg"
                  style={{ height }}
                />
              )}
            />
          ),
          label: (
            <Link
              href={routes.agro.workOrders.newType(
                WorkOrderType.Transport,
                WorkOrderType.Repair
              )}
            >
              <FormattedMessage
                id="workOrders.machineryWO"
                defaultMessage="Machinery Work Order"
              />
            </Link>
          ),
        }
      : null,
    canAccessWoType(WorkOrderType.Buildings)
      ? {
          key: "infrastructure-wo",
          icon: (
            <Icon
              component={() => (
                <img
                  src="/images/icons/building-structure.svg"
                  style={{ height }}
                />
              )}
            />
          ),
          label: (
            <Link
              href={routes.agro.workOrders.newType(
                WorkOrderType.Buildings,
                WorkOrderType.Roads
              )}
            >
              <FormattedMessage
                id="workOrders.infrastructureWO"
                defaultMessage="Infrastructure Work Order"
              />
            </Link>
          ),
        }
      : null,
    currentTenant.features.purchaseOrders || currentTenant.features.saleOrders
      ? {
          type: "divider",
        }
      : null,
    currentTenant.features.purchaseOrders && permissions.purchase?.write
      ? {
          key: "purchase",
          icon: (
            <Icon
              component={() => (
                <img src="/images/icons/product.svg" style={{ height }} />
              )}
            />
          ),
          label: (
            <Link href={routes.inventory.purchases.new}>
              <FormattedMessage id="inventoryPurchaseOrders.entityName" />
            </Link>
          ),
        }
      : null,
    currentTenant.features.saleOrders && permissions.sale?.write
      ? {
          key: "sale",
          icon: (
            <Icon
              component={() => (
                <img src="/images/icons/sales.svg" style={{ height }} />
              )}
            />
          ),
          label: (
            <Link href={routes.inventory.sales.new}>
              <FormattedMessage id="inventorySaleOrders.entityName" />
            </Link>
          ),
        }
      : null,
    {
      type: "divider",
    },
    permissions.financeOrder?.write && currentTenant.features.expenses
      ? {
          key: "expense",
          icon: (
            <Icon
              component={() => (
                <img src="/images/icons/expense.svg" style={{ height }} />
              )}
            />
          ),
          label: (
            <Link href={routes.finance.expenseOrders.new}>
              <FormattedMessage id="expenseOrders.entityName" />
            </Link>
          ),
        }
      : null,
    permissions.cropField?.write
      ? {
          key: "scouting-note",
          icon: (
            <Icon
              component={() => (
                <img src="/images/icons/notes.svg" style={{ height }} />
              )}
            />
          ),
          label: (
            <Link href={routes.agro.scoutingNotes.index + "#new"}>
              <FormattedMessage
                id="cropFields.notes"
                defaultMessage="Scouting Notes"
              />
            </Link>
          ),
        }
      : null,
  ];

  if (!items.find((i) => i?.key)) return null;

  return (
    <Dropdown menu={{ items }}>
      <Tooltip
        placement="left"
        title={<FormattedMessage id="quickAdd" defaultMessage="Quick links" />}
      >
        <Button
          type="primary"
          shape="circle"
          icon={<PlusOutlined />}
          style={{ marginRight: 8 }}
        />
      </Tooltip>
    </Dropdown>
  );
}
