import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
  ApolloClient,
} from "@apollo/client";

import {
  ImportsDocument,
  ImportsQuery,
  ImportsQueryVariables,
  ImportCreateMutation,
  ImportCreateMutationVariables,
  ImportCreateDocument,
  ImportQuery,
  ImportQueryVariables,
  ImportDocument,
  ImportStatusTypeQuery,
  ImportStatusTypeDocument,
  ImportKindType,
  ImportUpdateMutation,
  ImportUpdateMutationVariables,
  ImportUpdateDocument,
  ImportFireEventMutation,
  ImportFireEventMutationVariables,
  ImportFireEventDocument,
  ImportModulesType,
} from "../../graphql";
import { useIntl } from "react-intl";
import { useCallback, useMemo } from "react";
import { formatImportStatus } from "../../formats/imports";
import { useCurrentUser } from "./users";

export function useImportStatusOptions() {
  const [load, { data, loading }] = useLazyQuery<ImportStatusTypeQuery>(
    ImportStatusTypeDocument
  );

  return {
    load,
    loading,
    options: data?.__type?.enumValues?.map((t) => ({
      key: t.name,
      label: formatImportStatus(t.name),
    })),
  };
}

export function useImportModules() {
  const intl = useIntl();
  const { currentTenant, user } = useCurrentUser();

  const moduleOptions = useMemo(() => {
    const moduleOptions: {
      key: ImportModulesType;
      label: string;
      group: string;
    }[] = [];

    if (!currentTenant || !user) return moduleOptions;

    if (currentTenant.features.employees) {
      if (user.permissions.employee?.write)
        moduleOptions.push({
          key: ImportModulesType.Employee,
          label: intl.formatMessage({
            id: `imports.modules.employee.plural`,
          }),
          group: intl.formatMessage({ id: "hr" }),
        });

      if (user.permissions.position?.write)
        moduleOptions.push({
          key: ImportModulesType.Position,
          label: intl.formatMessage({
            id: `imports.modules.position.plural`,
          }),
          group: intl.formatMessage({ id: "hr" }),
        });
    }

    if (user.permissions.item?.write)
      moduleOptions.push({
        key: ImportModulesType.Variant,
        label: intl.formatMessage({
          id: `imports.modules.variant.plural`,
        }),
        group: intl.formatMessage({ id: "inventory" }),
      });

    if (user.permissions.itemCategory?.write)
      moduleOptions.push({
        key: ImportModulesType.ItemCategory,
        label: intl.formatMessage({
          id: `imports.modules.itemCategory.plural`,
        }),
        group: intl.formatMessage({ id: "inventory" }),
      });

    if (currentTenant.features.accounting && user.permissions.account?.write)
      moduleOptions.push({
        key: ImportModulesType.Account,
        label: intl.formatMessage({
          id: `imports.modules.account.plural`,
        }),
        group: intl.formatMessage({ id: "finance" }),
      });

    if (
      !!(
        currentTenant.features.inventoryStock ||
        currentTenant.features.expenses ||
        currentTenant.features.saleOrders ||
        currentTenant.features.purchaseOrders
      ) &&
      user.permissions.counterparty?.write
    )
      moduleOptions.push({
        key: ImportModulesType.Counterparty,
        label: intl.formatMessage({
          id: `imports.modules.counterparty.plural`,
        }),
        group: intl.formatMessage({ id: "finance" }),
      });

    if (currentTenant.features.machinery && user.permissions.machine?.write)
      moduleOptions.push({
        key: ImportModulesType.Machine,
        label: intl.formatMessage({
          id: `imports.modules.machine.plural`,
        }),
        group: intl.formatMessage({ id: "fleet" }),
      });

    if (user.permissions.activity?.write)
      moduleOptions.push({
        key: ImportModulesType.Activity,
        label: intl.formatMessage({
          id: `imports.modules.activity.plural`,
        }),
        group: intl.formatMessage({ id: "agriculture" }),
      });

    if (user.permissions.cropField?.write)
      moduleOptions.push({
        key: ImportModulesType.CropField,
        label: intl.formatMessage({
          id: `imports.modules.cropField.plural`,
        }),
        group: intl.formatMessage({ id: "agriculture" }),
      });

    if (user.permissions.weatherMeasurement?.write)
      moduleOptions.push({
        key: ImportModulesType.WeatherMeasurement,
        label: intl.formatMessage({
          id: `imports.modules.weatherMeasurement.plural`,
        }),
        group: intl.formatMessage({ id: "agriculture" }),
      });

    return moduleOptions;
  }, [currentTenant, intl, user]);

  const formatModuleName = useCallback(
    (moduleName: ImportModulesType) => {
      const moduleGroup = moduleOptions.find((v) => {
        return v.key === moduleName;
      });

      return `${moduleGroup?.label} / ${moduleGroup?.group}`;
    },
    [moduleOptions]
  );

  return { moduleOptions, formatModuleName };
}

export function useImportKinds() {
  const intl = useIntl();

  const kindOptions = Object.entries(ImportKindType).map(([, key]) => ({
    key,
    label: intl.formatMessage({ id: `imports.kinds.${key}` }),
  }));

  return { kindOptions };
}

export function useImports(
  options?: QueryHookOptions<ImportsQuery, ImportsQueryVariables>
) {
  const { loading, data, refetch } = useQuery(ImportsDocument, options);

  if (!data || !data.imports || !data.imports.items) {
    return { loading };
  }

  const items = data.imports.items;
  const totalCount = data.imports.totalCount;

  return { loading, items, totalCount, refetch };
}

export function useImport({
  id,
  onCompleted,
}: {
  id: string;
  onCompleted?: (data: ImportQuery) => void;
}) {
  const pollInterval = 5000;

  const { data, startPolling, stopPolling } = useQuery<
    ImportQuery,
    ImportQueryVariables
  >(ImportDocument, {
    variables: { id },
    pollInterval,
    notifyOnNetworkStatusChange: true, // it is necessary to trigger onCompleted on polling
    onCompleted,
  });

  return { import: data?.import, pollInterval, startPolling, stopPolling };
}

export function watchImport(
  client: ApolloClient<object>,
  id: string,
  onCompleted: (data?: ImportQuery | null) => boolean
) {
  const pollInterval = 5000;

  const query = client.watchQuery<ImportQuery, ImportQueryVariables>({
    query: ImportDocument,
    variables: { id },
    pollInterval,
  });

  return query.subscribe((observer) => {
    if (onCompleted(observer.data)) {
      query.stopPolling();
    }
  });
}

export function useImportCreate(
  options?: MutationHookOptions<
    ImportCreateMutation,
    ImportCreateMutationVariables
  >
) {
  return useMutation(ImportCreateDocument, options);
}

export function useImportUpdate(
  options?: MutationHookOptions<
    ImportUpdateMutation,
    ImportUpdateMutationVariables
  >
) {
  return useMutation(ImportUpdateDocument, options);
}

export function useImportFireEventMutation(
  options?: MutationHookOptions<
    ImportFireEventMutation,
    ImportFireEventMutationVariables
  >
) {
  return useMutation(ImportFireEventDocument, options);
}
